import WhatsDetail from '../views/whatsapp/Detail.vue';

const rebranding = window.$rebranding.active;

const routes = [
  {
    name: 'WhatsApp',
    path: '/',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/Dashboard.vue'),
  },
  {
    name: 'WhatsRequest',
    path: 'request',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsapp/Index.vue'),
  },
  {
    name: 'WhatsAppSend',
    path: 'send',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => (rebranding ? import('../views/rebranding/whatsapp/Send.vue') : import('../views/whatsapp/Send.vue')),
  },
  {
    name: 'SuccessWhatsApp',
    path: 'success',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsapp/Success.vue'),
  },
  {
    name: 'WhatsAppInsight',
    path: 'insights',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/Insights.vue'),
  },
  {
    name: 'WhatsAppDetail',
    path: 'history/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: WhatsDetail,
  },
  {
    name: 'WhatsAppCampaigns',
    path: 'campaigns',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/Campaigns.vue'),
  },
  {
    name: 'WhatsAppCampaignsDetail',
    path: 'campaigns/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/CampaignDetail.vue'),
  },
  {
    name: 'WhatsAppHistory',
    path: 'history',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/History.vue'),
  },
  {
    name: 'WhatsAppExport',
    path: 'exports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/Exports.vue'),
  },
  {
    name: 'WhatsAppTemplates',
    path: 'templates',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => (rebranding ? import('../views/rebranding/whatsapp/Templates.vue') : import('../views/whatsapp/Templates.vue')),
  },
  {
    name: 'WhatsAppNewTemplate',
    path: 'templates/new',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => (rebranding ? import('../views/rebranding/whatsapp/NewTemplate.vue') : import('../views/whatsapp/NewTemplate.vue')),
  },
  {
    name: 'WhatsDenyList',
    path: 'denylist',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (rebranding ? import('../views/rebranding/whatsapp/Blacklist.vue') : import('../views/whatsapp/Blacklist.vue')),
  },
  {
    name: 'WhatsOptouts',
    path: 'optouts',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/Optouts.vue'),
  },

  // Novo
  {
    name: 'WhatsApp',
    path: 'jv',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsapp/Dashboard.vue'),
  },
  {
    name: 'WhatsAppJavaSend',
    path: 'jv/send',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsappj/Send.vue'),
  },
  {
    name: 'WhatsAppNumbers',
    path: 'jv/numbers',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/rebranding/whatsappj/Numbers.vue'),
  },
  {
    name: 'WhatsAppNewNumber',
    path: 'jv/numbers/new',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/rebranding/whatsappj/NewNumber.vue'),
  },
  {
    name: 'SuccessWhatsAppMeta',
    path: 'jv/success',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsappj/Success.vue'),
  },
  {
    name: 'WhatsAppHistory',
    path: 'jv/history',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsappj/History.vue'),
  },
  {
    name: 'WhatsAppDetail',
    path: 'jv/history/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsappj/Detail.vue'),
  },
  {
    name: 'WhatsAppTemplates',
    path: 'jv/templates',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsappj/Templates.vue'),
  },
  {
    name: 'WhatsAppNewTemplate',
    path: 'jv/templates/new',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsappj/NewTemplate.vue'),
  },
  {
    name: 'WhatsAppNewDenylist',
    path: 'jv/denylist',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsappj/Blacklist.vue'),
  },
  {
    name: 'WhatsAppNumbers',
    path: 'jv/numbers',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/whatsappj/Numbers.vue'),
  },
  {
    name: 'WhatsappReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/whatsapp/reports/Index.vue'),
    children: [
      {
        name: 'WhatsappStatReport',
        path: 'stat',
        component: () => import('@/views/rebranding/whatsapp/reports/Stat.vue'),
      },
      {
        name: 'WhatsappHistoryReport',
        path: 'history',
        component: () => import('@/views/rebranding/whatsapp/reports/History.vue'),
      },
      {
        name: 'WhatsappUsageReport',
        path: 'usage',
        component: () => import('@/views/rebranding/whatsapp/reports/Usage.vue'),
      },
      {
        name: 'WhatsappCampaignReport',
        path: 'campaign',
        component: () => import('@/views/rebranding/whatsapp/reports/Campaign.vue'),
      },
      {
        name: 'WhatsappReportExports',
        path: 'exports',
        component: () => import('@/views/rebranding/whatsapp/reports/Export.vue'),
      },
    ],
  },
  {
    name: 'WhatsAppMetaReports',
    path: 'jv/reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/whatsappj/reports/Index.vue'),
    children: [
      {
        name: 'WhatsAppMetaStatReport',
        path: 'stat',
        component: () => import('@/views/whatsappj/reports/Stat.vue'),
      },
      {
        name: 'WhatsAppMetaHistoryReport',
        path: 'history/:id?',
        component: () => import('@/views/whatsappj/reports/History.vue'),
      },
      {
        name: 'WhatsAppMetaUsageReport',
        path: 'usage',
        component: () => import('@/views/whatsappj/reports/Usage.vue'),
      },
      {
        name: 'WhatsAppMetaCampaignReport',
        path: 'campaign',
        component: () => import('@/views/whatsappj/reports/Campaign.vue'),
      },
      {
        name: 'WhatsAppMetaReportExports',
        path: 'exports',
        component: () => import('@/views/whatsappj/reports/Export.vue'),
      },
    ],
  },
  {
    name: 'WhatsAppUsage',
    path: 'usage',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    // component: WhatsAppUsage,
    component: () => import('../views/rebranding/whatsapp/Usage.vue'),
    children: [
      {
        name: 'WhatsAppReport',
        path: '',
        component: () => import('@/views/rebranding/whatsapp/Report.vue'),
      },
      {
        name: 'WhatsAppStats',
        path: 'stats',
        component: () => import('@/views/rebranding/whatsapp/stats/Index.vue'),
      },
      {
        name: 'WhatsAppHistoryTab',
        path: 'history',
        component: () => import('@/views/rebranding/whatsapp/history/History.vue'),
      },
    ],
  },
];

export default routes;
